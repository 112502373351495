// Polyfills for older browsers
import 'whatwg-fetch'
import 'promise-polyfill/src/polyfill'

import { cpEndPoint, queryString } from './vars'

import { bufferToString, stringToBuffer } from './utils'

/**
 *
 * @param {String} name
 * @param {Object} params
 * @returns {Promise<Response | void>}Con
 */
export const fetchContent = (name, params) => {
   // const bodyBuf = Buffer.from(JSON.stringify(params), 'utf-8')

   // Set GET params from host page
   let getParams = '';
   if (queryString && queryString.charAt(0) === '?') {
      getParams = queryString.substring(1)
   }
   params.getParams = getParams

   // console.error(`params: ${JSON.stringify(params)}`)

   const bodyBuf = stringToBuffer(JSON.stringify(params))

   return fetch(cpEndPoint + name, {
      method:         'POST',
      mode:           'cors',
      credentials:    'include', // 'include'
      headers:        {
         'Content-Type':                'application/json; charset=utf-8',
         // XXX 'Access-Control-Allow-Origin': `https://${requestHost}`,
         'Content-Length':              bodyBuf.byteLength
      },
      redirect:       'follow', // manual, *follow, error
      referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
      body:           bufferToString(bodyBuf) // body data type must match "Content-Type" header
   })
      .then(response => response.json())

      // TODO: handle exception better
      .catch(function(e) {
         console.error('parsing failed: ' + e.toString())
         // alert('Failed parsing response: ' + ex)
      })
}
