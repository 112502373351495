/**
 * File: moduleParams
 * Project: api-client
 * Author: lents
 * Date: 9/16/20
 */

const validArgs = [
   'content',      // type of content
   'wrapperClass', // XXX: probably should be deprecated
   'injectionId',  // an id into which we should inject the content
   // reservations
   'rezType',      // 'rsvp' or 'normal' (default)
   'rezTypeId',
   // product info
   'catId',
   'element', //
   'storeName',
   'itemList',    // list
   'itemDetail',
   'clickDestination',
   'storeId',
   'coupon', // added for product 2021-10-06
   'goo' // testing
];

// check for args
let args = document.getElementById('cpData').dataset

// Do natively instead
export const cpModules = args.hasOwnProperty('content') ? args.content.split(',') : []
if (cpModules.length) {
   delete args.content
}

// Object for building module arguments
export const params = {}

// I don't like this
validArgs.forEach(arg => {
   if (arg in args) {
      switch (arg) {
         case 'wrapperClass': // don't need to pass
         case 'injectionId':  // don't need to pass
         case 'rezType':
         case 'rezTypeId':
         case 'element':
         case 'itemList':
         case 'itemDetail':
         case 'clickDestination':
         case 'storeId':
         case 'coupon':
            // not here
            // params[arg] = args[arg].split(',')
            params[arg] = args[arg]
            break;
            // XXX: Hasn't been needed
         case 'goo':
            try {
               params[arg] = JSON.parse(args[arg])
            }
            catch (error) {
               params[arg] = args[arg]
            }
            break;
         default:
            break;
      }
      // console.error(`${arg} : ${params[arg]}`)
   }
})
