import { cpHost } from './vars'
import { requiredScripts } from './base'

/**
 *
 * @param {String} encodedHtml
 * @returns {String}
 *
 * decode input html, which is encoded on the server by PHP htmlentities()
 * (does no harm if html is not encoded
 *  https://stackoverflow.com/questions/1912501/unescape-html-entities-in-javascript/34064434#34064434
 *  XXX: should this be treated as a base64 object? (no evidence yet)
 */
export const htmlDecode = (encodedHtml) => {

   try {
      let cpContent = atob(encodedHtml)
      const htmlDoc = new DOMParser().parseFromString(cpContent, 'text/html')
      return htmlDoc.documentElement.textContent
   }
   catch (error) {
      // XXX: Deal with error
      console.error(`Error decoding incoming html: ${error.message}`)
      return ''
   }
}

/**
 *
 * @param {String} cpContent
 * @param {String} divClass
 * @returns {HTMLDivElement}
 *
 * wraps the incoming content into an encompassing <div> wrapper
 */
export const divWrap = (cpContent, divClass) => {
   // create a div to wrap the content into
   let cpDiv = document.createElement('div')
   cpDiv.setAttribute('id', 'captina_content_wrapper')
   if (divClass) {
      cpDiv.setAttribute('class', divClass)
   }
   cpDiv.innerHTML = cpContent
   return cpDiv
}

/**
 * fix local img src links for off-site use
 *
 * @param {HTMLDivElement} divElement
 * @param {String} domain
 * @returns {HTMLDivElement}
 */
export const fixImageLinks = (divElement, domain) => {
   // convert <img> src URIs from local to remote
   try {
      const imgElements = divElement.getElementsByTagName('img')
      if (imgElements) {
         for (let imgElem of imgElements) {
            let newSrc = imgElem.getAttribute('src')
            // add domain to image links with regex magic
            // XXX: this doesn't look exactly right
            //   needs explaining at least
            imgElem.src = newSrc.replace(/^\/((?:cp|lz|repoz)\/.+$)/, `https://${domain}/$1`)
         }
      }
      return divElement
   }
   catch (error) {

      // fail silently  TODO: handle more usefully
      console.error(`Couldn't parse input. Error: ${error.message}`)
      return new HTMLDivElement() // ha
   }
}

/**
 * @param {Array<String>} inputArray
 * @returns {Array<Element>}
 */
export function createScriptElements(inputArray) {
   // shouldn't be empty, but be safe
   if (!inputArray) {
      return []
      // @david, this is likely better here:
      //   inputArray = ['/lz_client/base.min.js'];
   }

   let scripts = [],
      baseJS = '/lz_client/base.min.js',
      // exclude base, it gets added later
      scriptsArray = inputArray
         .filter(url => !(url.includes(baseJS)))
   // XXX: This will add base to every module:
   //  .unshift(baseJS)

   // We weeded baseJS out in case it was already there,
   // now add it to the top of the stack
   // scriptsArray.unshift(baseJS)
   // loop through the input array and load if not already
   for (let jsSrc of scriptsArray) {
      // skip if jsSrc is already in requiredScripts
      if (requiredScripts.some(path => jsSrc.includes(path))) {
         continue;
      }
      // skip if already in document.scripts
      if ([...document.scripts].some(js => js.src && js.src.includes(jsSrc))) {
         continue;
      }
      // add this script to the page
      //  add scheme to local URLs passed from backend
      if (jsSrc.startsWith('/')) {
         jsSrc = `https://${cpHost}${jsSrc}`
      }

      let script = document.createElement('script')
      script.src = jsSrc
      script.type = 'text/javascript'
      script.crossOrigin = 'anonymous'
      scripts.push(script)
   }

   return scripts
}

/**
 *
 * @param {Array<String>}styleArray
 * @return {Array<Element>}
 */
export function createStyleElements(styleArray) {
   let styles = []
   styleArray.forEach(styleSrc => {
      let style = document.createElement('link')
      if (styleSrc.startsWith('/')) {
         style.href = `https://${cpHost}${styleSrc}`
      }
      else {
         style.href = styleSrc
      }
      style.rel = 'stylesheet'
      style.type = 'text/css'
      styles.push(style)
   })
   return styles
}

/**
 * @link https://developers.google.com/web/updates/2012/06/How-to-convert-ArrayBuffer-to-and-from-String
 * quick conversion of string -> ArrayBuffer to avoid using Buffer
 * @param {String } str
 * @returns {ArrayBuffer}
 */
export function stringToBuffer(str) {
   const buf = new ArrayBuffer(str.length*2); // 2 bytes for each char
   const bufView = new Uint16Array(buf);
   for (let i = 0, strLen = str.length; i < strLen; i++) {
      bufView[i] = str.charCodeAt(i);
   }
   return buf;
}

/**
 *
 * @param buf
 * @returns {string | *}
 */
export function bufferToString(buf) {
   return String.fromCharCode.apply(null, new Uint16Array(buf));
}

/**
 *
 * might be useful for debugging the buffer
 * @param buf
 * @returns {[]}
 */
export function bufferToArray(buf) {
   // console.error('bodyBuf: ' + bodyBuf.))
   const viewArray = []
   new Uint16Array(buf)
      .forEach(val => {
         viewArray.push(String.fromCharCode(val))
      })
   return viewArray
}
/**
 *
 * @param {string} fnStr
 * @returns {Function}
 *
 * convert string to function (for doOnloadStuff)
 * https://stackoverflow.com/questions/12443330/how-do-i-convert-a-string-to-a-function-reference-in-javascript
 * XXX: untested

 */
export const stringToFunction = function(fnStr) {
   return new Function(fnStr)
}
