'use strict'

// import { has } from 'rambdax'
import './polyfills'
import { fetchContent } from './fetchContent'
import { cpHost } from './vars'
import {
   createScriptElements,
   createStyleElements,
   divWrap,
   fixImageLinks,
   htmlDecode,
   stringToFunction
} from './utils'

import { cpModules, params } from './moduleParams'

// console.error(`params: ${JSON.stringify(params)}`)
// console.error(`modules: ${JSON.stringify(cpModules)}`)

const divInject = (injectionId, divToInject) => {
   if (injectionId) {
      try {
         // Get the user-defined wrapper id
         let section = document.getElementById(injectionId)
         if (!section) {
            // do something
         }
         // inject the content div
         section.appendChild(divToInject)

         // Add Captina-specific classes to the new outer wrapper
         // XXX: product_info doesn't have lz_outer_wrapper, has <div id="captina_products">
         let classes = (section.querySelector('#lz_outer_wrapper') || section.querySelector('#captina_products'))
            .getAttribute('class')

         if (classes) {
            classes = classes.trim()
               .replace(/\s+/g, ' ') // collapse extra spacing

            document.getElementById('captina_content_wrapper')
               .setAttribute('class', classes) // works
            // use spread operator :)
            // .classList
            // .add(...classes.split(' '))
         }
      }
      catch (e) {
         // TODO: better exception handling
         console.error(`error: ${e.message}`)
      }
   }
   else {
      alert(`Couldn't find an injectionId setting (data-injection-id)`)
   }
}

cpModules.forEach(module => {
   // XXX: parse args here ?
   fetchContent(module, params)
      .then(json => {

         // Set up the wrapping HTML element (div)
         let wrapperClass = params.wrapperClass ?? ''

         // A (self-added) id in which to inject the content
         let injectionId = params.injectionId || '';

         // The div containing the content
         let cpDiv;

         // normal page content
         if (json.hasOwnProperty('body')) {
            // need some processing on html here, or on what comes from the server
            // XXX: json.body is not String, but rather  ReadableStream<Uint8Array> | null
            //   Concerning, but this basically works
            let body = htmlDecode(json.body)
            // TODO: leave out the `divWrap()` call if divClass is empty
            cpDiv = fixImageLinks(divWrap(body, wrapperClass), cpHost) // wrap into live div
            divInject(injectionId, cpDiv)
         }

         // product content
         if (json.hasOwnProperty('product_content') || json.hasOwnProperty('content')) {
            let content = json['product_content'] ?? json['content']
            if (content.length > 0) {
               content = htmlDecode(content)
               cpDiv = fixImageLinks(divWrap(content, wrapperClass), cpHost)
               divInject(injectionId, cpDiv)
            }
         }
         //   load JS into <head>
         if (json.hasOwnProperty('head_scripts') && json['head_scripts'].length >= 1) {
            // load JS into <head>
            createScriptElements(json['head_scripts'])
               .forEach(script => document.head.appendChild(script))
         }
         // load css into <head>
         if (json.hasOwnProperty('styles') && json.styles.length >= 1) {
            createStyleElements(json.styles)
               .forEach(style => document.head.appendChild(style))
         }
         // load JS into <body>
         if (json.hasOwnProperty('scripts') && json.scripts.length >= 1) {
            createScriptElements(json.scripts)
               .forEach(script => document.body.appendChild(script))
         }
         // onload event(s)
         if (json.hasOwnProperty('onload') && json.onload.length >= 1) {
            // https://developer.mozilla.org/en-US/docs/Web/API/EventTarget/addEventListener
            const once = {
               once: true
            }
            // noinspection JSUnusedLocalSymbols
            window.addEventListener('DOMContentLoaded', (event) => {
               try {
                  stringToFunction(json.onload)
               }
               catch (error) {
                  console.error(`Error: ${error.message}`)
                  // console.error(`Event: ${event}`)
               }
            }, once) // invoke at most once
         }
         // return json
      })
      // Catch exceptions
      .catch((ex) => {
         console.error(`Error: ${ex.message}`);
      })// or something

})
// XXX: The following part is SquareSpace specific
//    maybe site-specific
// let section = document.getElementById('sections')
// section.appendChild(cpDiv)

// XXX: use footer for "default"?
//    for Sutro, use <footer> and insert content before
// let footer = document.getElementsByTagName('footer')
// window.footer = footer // XXX: debug
// XXX: check footer here, in case none found, bail out
// footer = footer.length ? footer[0] : footer
// Inject content before footer
// let parent = footer.parentNode
// let section = parent.insertBefore(cpDiv, footer)
// window.section = section // XXX: debug
